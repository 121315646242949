import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "antd";
import useStore from "../../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import FormComponent from "./FormComponent";
interface EditProps {
	EditID: number;
	EditData: (data: any) => Promise<any>;
	closeEditTranslationsPopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
}
const EditComponent: React.FC<EditProps> = ({
	EditID,
	EditData,
	visible,
	closeEditTranslationsPopup,
	ReadData,
}) => {		
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const { ROOT, COMMON} = useStore();
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();
	const history = useHistory();
	const { fetchLanguage, languageList } = COMMON;
	useEffect(() => {
		!languageList && fetchLanguage()
		if (EditID) {
			ReadData(EditID).then(({ data }) => {
				form.setFieldsValue({
					id: data?.id,
					key: data?.key,					
				});				
				languageList.map((item:any) => {
					form.setFieldsValue({[item.local_key]: data?.text[item.local_key]})
				});
			});
		}
	}, [EditID]);

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.id=EditID;
		EditData(data)
			.then(() => {
				close();
			})
			.catch((e) => {
				AssignErrorToInput(form, e?.data?.errors);
			})
			.finally(() => setSaving(false));
	};
	const listRoute = () =>{
		history.push('/translation');
  }

	const close = () => {
		form.resetFields();
		closeEditTranslationsPopup();
	};
	return (
		<Modal
			centered
			title={t("EDIT_TRANSLATION")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			footer={[
				<Button
					key="1"
					loading={saving}
					form="editTranslationForm"
					type="primary"
					htmlType="submit"
				>
					{t('SAVE')}
				</Button>,
				<Button key="2" onClick={close}>
					{t('CANCEL')}
				</Button>,
			]}
		>
		<p style={{color:'red'}}>{"* " + t('TRANSLATION_WARNING_MESSAGE')}</p>
		<FormComponent
			formId="editTranslationForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			isEdit={true}
			listRoute={listRoute}
		/>
	</Modal>
	);
};

export default EditComponent;
