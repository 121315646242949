import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { TranslationRequest } from "../../../../requests/TranslationRequtest";
import useStore from "../../../../store";

interface FormDataProps {
	formId:string;
	form:any;
	handleSubmit:(data: any) => void |any;
	saving:boolean;	
	isEdit:boolean;
	listRoute:() => void;
}
const FormComponent: React.FC<FormDataProps> = observer(({
		formId,
		form,
		handleSubmit,
		isEdit
	}) => {
		const {COMMON } = useStore();
		const { t } = useTranslation();
	
		const {
			languageList,
			fetchLanguage,
		} = COMMON;
		useEffect(()=>{			
				fetchLanguage()
		},[fetchLanguage,])
	
		return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
				<InputBox.Text
					required
					label={t('KEY')}
					name="key"
					placeholder={t('KEY')}
					maxLength={255}
					disabled={isEdit}
					rules={TranslationRequest(t).key}
				/>
				{languageList && languageList.map((item:any, index:number) => {
					return (
						<div key={"language"+index}>
							<InputBox.Text
								name={item.local_key}
								placeholder={item.name}
								label={item.name}
								maxLength={255}
								rules={TranslationRequest(t).local_key}
							/>
						</div>
					);
				})}
			</FormBox>
  );
});
export default FormComponent;
