import React, { useEffect } from "react";
import { Layout, Menu } from "antd";
import LogoComponent from "../components/LogoComponent";
import Config from "../../../config/Config";
import { Link } from "react-router-dom";
import useStore from "../../../store";
import { UsergroupAddOutlined, DollarCircleOutlined, FileImageOutlined, SettingOutlined } from '@ant-design/icons';
import DashboardImage from "../../../assets/images/Home.svg";
// import UserManagementImage from "../../../assets/images/Home.svg";
import SavingGroupImage from "../../../assets/images/savinggroup-icon.svg";
import walletImage from "../../../assets/images/wallet-icon.svg";
import transactionsImage from "../../../assets/images/transactions-icon.png";
import FinesImage from "../../../assets/images/fines-icon.svg";
import professionImage from "../../../assets/images/profession-icon.png";
// import confederationImage from "../../../assets/images/confederation-icon.svg";
import loanRequestImage from "../../../assets/images/loan-request-icon.png";
import meetingImage from "../../../assets/images/meeting-icon.svg";
import loandisbursedImage from "../../../assets/images/loandisbursed-icon.svg";
import cmsImage from "../../../assets/images/cms-icon.svg";
import templatesImage from "../../../assets/images/templates-icon.svg";
import groupfinanceImage from "../../../assets/images/Group_Finance_Management.svg";
import newsImage from "../../../assets/images/News-icon.svg";
import villageAgentImage from "../../../assets/images/Group_all.svg";
import electionprocessImage from "../../../assets/images/election-process.svg";
import confederationImage from "../../../assets/images/confederation-icon.svg";
import importIcon from "../../../assets/images/Import-Icon.svg";
import translateImage from "../../../assets/images/translate.svg";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
interface AppSidebarViewProps {
	visible?: boolean;
	collapsed?: boolean;
}

const SidebarView: React.FC<AppSidebarViewProps> = observer(({ visible, collapsed }) => {
	const { t } = useTranslation();
	const { AUTH, COMMON } = useStore();
	const { permissions, activeKey, setActiveKey } = AUTH;
	const { allPermissionList } = COMMON
	const currentUrl = window.location.href.split('/');

	const setSelectedMenuItem = (key:any) => {
		setActiveKey(key.keyPath);
	}

	const setDefaultSelectedMenuItem = () => {
		if(currentUrl[3]=='user-management' || currentUrl[3]=='role-management'){
			setActiveKey([currentUrl[3],'user-role-management']);
		} else if(currentUrl[3]=='faq-category-management' || currentUrl[3]=='faq-management'){
			setActiveKey([currentUrl[3],'faq-faq-category']);
		}  else if(currentUrl[3]=='donation' || currentUrl[3]=='donation-subject' || currentUrl[3]=='donation-campaign'){
			setActiveKey([currentUrl[3],'donation-management']);
		} else {
			setActiveKey([currentUrl[3]]);
		}
	}

	useEffect(() => {
		!activeKey && setDefaultSelectedMenuItem();
	}, [activeKey, setActiveKey]);

	useEffect(()=>{
		//
	}, [allPermissionList]);

	return !visible ? null : (
		<Layout.Sider
			collapsed={collapsed}
			width={Config.sidebar_width}
			className="main__page__appsidebar"
		>
			<LogoComponent />
			{activeKey && <Menu mode="inline" theme="dark" defaultSelectedKeys={[activeKey[0]]} defaultOpenKeys={[activeKey[activeKey.length - 1]]}  onClick={setSelectedMenuItem}>
				<Menu.Item key="dashboard">					
					<Link to="/dashboard"><img alt="images" src={DashboardImage} /> <span>{t("DASHBOARD")}</span></Link>
				</Menu.Item>
				<Menu.SubMenu key="user-role-management" icon={<UsergroupAddOutlined />} title={t("USER_MANAGEMENT")} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_USER) >= 0 || permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_ROLE) >= 0) ? "inherit" : "none"}}>
					<Menu.Item key="user-management" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_USER) >= 0 ? "inherit" : "none"}}>
						<Link to="/user-management">{t("USERS")}</Link>
					</Menu.Item>
					<Menu.Item key="role-management" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_ROLE) >= 0 ? "inherit" : "none"}}>
						<Link to="/role-management">{t("ROLE")}</Link>
					</Menu.Item>
				</Menu.SubMenu>
				<Menu.Item key="group-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_GROUP) >= 0) ? "inherit" : "none"}}>
					<Link to="/group-management" > <img alt="images" src={SavingGroupImage}/> <span>{t("SAVING_GROUP")}</span></Link>				
				</Menu.Item>
				<Menu.Item key="wallet-management" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.WALLET_LIST) >= 0 ? "inherit" : "none"}}>
					<Link to="/wallet-management"> <img alt="images" src={walletImage}/> <span>{t("WALLET_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="transactions" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TRANSACTION) >= 0 ? "inherit" : "none"}}>
					<Link to="/transactions"><img alt="images" src={transactionsImage}/> <span>{t("TRANSACTIONS")}</span></Link>
				</Menu.Item>
				<Menu.Item key="profession-management"  style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_PROFESSION) >= 0 ? "inherit" : "none"}}>
					<Link to="/profession-management"> <img alt="images" src={professionImage}/>  <span>{t("PROFESSION")}</span></Link>
				</Menu.Item>
				<Menu.Item key="confederation-management"  style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_CONFEDERATION) >= 0 ? "inherit" : "none"}}>
					<Link to="/confederation-management" className="confederationLink"><img alt="images" src={confederationImage} className="confederationImg"/>  <span>{t("CONFEDERATION")}</span></Link>
				</Menu.Item>
				{/* <Menu.SubMenu key="faq-faq-category" icon={<QuestionCircleOutlined />} title={t("FAQ_MANAGEMENT")} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ) >= 0 || permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ_CATEGORY) >= 0) ? "inherit" : "none"}}>
					<Menu.Item key="faq-category-management" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ_CATEGORY) >= 0 ? "inherit" : "none"}}>
						<Link to="/faq-category-management"> <span>{t("FAQ_CATEGORY")}</span></Link>
					</Menu.Item>
					<Menu.Item key="faq-management" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ) >= 0 ? "inherit" : "none"}}>
						<Link to="/faq-management"> <span>{t("FAQ")}</span></Link>
					</Menu.Item>
				</Menu.SubMenu> */}
				<Menu.Item key="fine-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FINE) >= 0) ? "inherit" : "none"}}>
					<Link to="/fine-management"><img alt="images" src={FinesImage}/> <span>{t("FINE_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="group-finance-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.GROUP_FINANCE_MANAGEMENT) >= 0) ? "inherit" : "none"}}>
					<Link to="/group-finance-management"><img alt="images" src={groupfinanceImage}/> <span>{t("GROUP_FINANCE_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="meeting-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_MEETING) >= 0) ? "inherit" : "none"}}>
					<Link to="/meeting-management"> <img alt="images" src={meetingImage}/> <span>{t("MEETING_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="loan-request-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_USER_LOAN_REQUEST) >= 0) ? "inherit" : "none"}}>
					<Link to="/loan-request-management"> <img alt="images" src={loanRequestImage}/> <span>{t("LOAN_REQUEST_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="loan-disbursed-management" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_LOAN_DISBURSEMENT_LIST) >= 0 ? "inherit" : "none"}}>
					<Link to="/loan-disbursed-management"> <img alt="images" src={loandisbursedImage}/> <span>{t("LOAN_DISBURSED")}</span></Link>
				</Menu.Item>
				<Menu.Item key="cms-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_CMS) >= 0) ? "inherit" : "none"}}>
					<Link to="/cms-management"> <img alt="images" src={cmsImage}/> <span>{t("CMS_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="templates-management" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TEMPLATE) >= 0) ? "inherit" : "none"}}>
					<Link to="/templates-management"> <img alt="images" src={templatesImage}/> <span>{t("TEMPLATES")}</span></Link>
				</Menu.Item>
				<Menu.SubMenu key="donation-management" title={t("DONATION")} icon={<DollarCircleOutlined />} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION_SUBJECT) >= 0 || permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION) >= 0 || permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION_CAMPAIGN) >= 0) ? "inherit" : "none"}}>
					<Menu.Item key="donation" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION) >= 0 ? "inherit" : "none"}}>
						<Link to="/donation"> <span>{t("DONATION")}</span></Link>
					</Menu.Item>
					<Menu.Item key="donation-subject" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION_SUBJECT) >= 0 ? "inherit" : "none"}}>
						<Link to="/donation-subject"> <span>{t("DONATION_SUBJECT")}</span></Link>
					</Menu.Item>
					<Menu.Item key="donation-campaign" style={{display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION_CAMPAIGN) >= 0 ? "inherit" : "none"}}>
						<Link to="/donation-campaign"> <span>{t("DONATION_CAMPAIGN")}</span></Link>
					</Menu.Item>
				</Menu.SubMenu>
				<Menu.Item key="group-expense" icon={<DollarCircleOutlined />} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_GROUP_EXPENSE) >= 0) ? "inherit" : "none"}}>
					<Link to="/group-expense"> <span>{t("GROUP_EXPENSE")}</span></Link>
				</Menu.Item>
				<Menu.Item key="banner" icon={<FileImageOutlined />} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_BANNER) >= 0) ? "inherit" : "none"}}>
					<Link to="/banner"> <span>{t("BANNER_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="slider" icon={<FileImageOutlined />} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SLIDER) >= 0) ? "inherit" : "none"}}>
					<Link to="/slider"> <span>{t("SLIDER_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="news" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_NEWS) >= 0) ? "inherit" : "none"}}>
					<Link to="/news-management"> <img alt="images" src={newsImage}/> <span>{t("NEWS_MANAGEMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="village-agent" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_VILLAGE_AGENT) >= 0) ? "inherit" : "none"}}>
					<Link to="/village-agent"><img alt="images" src={villageAgentImage}/> <span>{t("VILLAGE_AGENT_ASSIGNMENT")}</span></Link>
				</Menu.Item>
				<Menu.Item key="election-process" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_ELECTION) >= 0) ? "inherit" : "none"}}>
					<Link to="/election-process"><img alt="images" src={electionprocessImage}/> <span>{t("ELECTION_PROCESS")}</span></Link>
				</Menu.Item>
				<Menu.Item key="translation" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TRANSLATION) >= 0) ? "inherit" : "none"}}>
					<Link to="/translation"><img alt="images" src={translateImage}/> <span>{t("TRANSLATION")}</span></Link>
				</Menu.Item>
				<Menu.Item key="settings" icon={<SettingOutlined />} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0) ? "inherit" : "none"}}>
					<Link to="/settings"> <span>{t("SETTINGS")}</span></Link>
				</Menu.Item>
				<Menu.Item key="reports" icon={<SettingOutlined />} style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.ALL_REPORTS) >= 0) ? "inherit" : "none"}}>
					<Link to="/all-reports"> <span>{t("REPORTS")}</span></Link>
				</Menu.Item>
				<Menu.Item key="import-data" style={{display:(permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_IMPORT_DATA) >= 0) ? "inherit" : "none"}}>
					<Link to="/import-data"><img alt="images" src={importIcon}/> <span>{t("IMPORT_DATA")}</span></Link>
				</Menu.Item>
			</Menu>}
		</Layout.Sider>
	);
});

export default SidebarView;
