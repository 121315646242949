import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button,} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { GridReadyEvent } from "ag-grid-community";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	openEditTranslationsPopup: (id: number) => void | any;
}

const ListComponent: React.FC<ListProps> = ({ setupGrid, openEditTranslationsPopup,}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => openEditTranslationsPopup(params.data?.id)}
				></Button>
			</div>
		);
	};

	return (
		<AgGridWrapper
			tooltipShowDelay={0}
			enableBrowserTooltips={true}
			type="serverSide"
			onGridReady={setupGrid}
			frameworkComponents={{ ActionRenderer}}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn field="id" headerName={t('SR_NO')}  filter="agNumberColumnFilter"  width={100}  pinned={"left"}/>
			<AgGridColumn
				width={587}
				field="key"
				headerName={t('KEY')}
				tooltipField="key"
			/>
			<AgGridColumn
				width={587}
				field="english"
				headerName="English"
				tooltipField="english"
			/>
			{/* <AgGridColumn
				width={200}
				field="group"
				headerName={t('GROUP')}
			/> */}
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
