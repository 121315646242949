import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { TranslationBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";

const TranslationView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { TRANSLATION } = useStore();
	const {
		setupGrid,
		create,
		update,
		details, 
		addTranslationPopup,
		openAddTranslationPopup,
		closeAddTranslationPopup,
		editTranslationID,
		editTranslationPopup,
		openEditTranslationPopup,
		closeEditTranslationPopup } = TRANSLATION;

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={TranslationBreadcrumb(t).path} />
				<HeaderButtons key={1} openAddTranslationsPopup={openAddTranslationPopup}/>
			</div>
			<ContentBox>
				<ListComponent setupGrid={setupGrid} openEditTranslationsPopup={openEditTranslationPopup}/>
				<AddComponent
					createData={create}
					visible={addTranslationPopup}
					closeAddTranslationsPopup={closeAddTranslationPopup}
				/>
				<EditComponent
					EditID={editTranslationID}
					EditData={update}
					ReadData={details}
					visible={editTranslationPopup}
					closeEditTranslationsPopup={closeEditTranslationPopup}
				/>
			</ContentBox>
		</>
	);
});

export default TranslationView;
